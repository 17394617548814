<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center">
				<div class="ucenter_main">
					<ucMenu num="7"></ucMenu>
					<div class="right_con">
						<div class="extend_main">
							<p class="fb">推广中心</p>
							<div class="links_area">
								<p>商家推广：<a :href="business_url" target="_blank">{{business_url}}</a><i class="iconfont icon-fuzhi" @click="copyUrl(business_url)"></i></p>
								<p>供应商推广：<a :href="supplier_url" target="_blank">{{supplier_url}}</a><i class="iconfont icon-fuzhi" @click="copyUrl(supplier_url)"></i></p>
							</div>
							<div class="tabs_wrap">
								<p class="tab" :class="extendType==2?'active':''" @click="changeExtendType('2')">合作商家</p>
								<p class="tab" :class="extendType==3?'active':''" @click="changeExtendType('3')">合作供应商</p>
							</div>
							<ul class="business_list" v-if="extendList.length>0">
								<li v-for="(item,index) in extendList" :key="index">
									<div class="infos">
										<div class="con_box">
											<p class="phone">{{item.mobile}}</p>
											<p class="time">注册时间：{{item.createtime}}</p>
										</div>
										<div class="logo">
											<img class="coverimg" :src="item.avatar" />
										</div>
									</div>
									<div class="contents">
										<div class="con">
											<p class="t1">{{item.yesterday}}</p>
											<p class="t2">昨日订单量</p>
										</div>
										<div class="con">
											<p class="t1">{{item.total}}</p>
											<p class="t2">总订单量</p>
										</div>
										<div class="con">
											<p class="t1">￥{{item.proffer_stay_thaw}}</p>
											<p class="t2">待解冻</p>
										</div>
										<div class="con">
											<p class="t1">￥{{item.proffer_already_thaw}}</p>
											<p class="t2">已解冻</p>
										</div>
									</div>
								</li>
							</ul>
							<p class="nodata" v-else>暂无数据~</p>
							<div class="turn_page">
								<el-pagination
								      :page-size="page_size"
								      layout="total,prev, pager, next, jumper"
								      prev-text="上一页"
								      next-text="下一页"
								      :total="listTotal"
								      hide-on-single-page
								      :current-page="page"
								      @current-change="currentChange">
								</el-pagination>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import { mapActions,mapState,mapMutations } from "vuex";
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import ucMenu from '../../components/ucenterMenu/ucmenu';

export default {
	name: "ucenterExtend",
	data() {
		return {
			business_url:"",
			supplier_url:"",
			extendType:"2",
			page:1,
			page_size:6,
			listTotal:0,
			extendList:[]
		}
	},
	components:{
		myHead,myFoot,ucMenu
	},
	computed:{
		...mapState({
			uids:(state)=>state.login.uid,
		}),
	},
	created(){
		this.uid = this.uids
		this.getExtendUrl({data:{
				uid:this.uid,
			},success:(res)=>{
			if(res.code===200){
				console.log('get extend url success',res)
				this.business_url = res.data.basiness_url
				this.supplier_url = res.data.supplier_url
			} else {
				this.$message({message: res.msg,type: 'error',});
			}
		}})
		this.getExtend()
	},
	methods: {
		...mapActions({
			getExtendUrl:"ucenter/getExtendUrl",
			getExtendList:"ucenter/getExtendList",
		}),
		copyUrl(url){//一键复制
			this.$copyText(url).then(()=>{
				this.$message({message: '复制成功~',type: 'success',});
			},()=>{
				this.$message({message: '复制失败！',type: 'error',});
			})
		},
		changeExtendType(type){
			this.extendType = type
			this.page = 1
			this.getExtend()
		},
		getExtend(){//获取商家 / 供应商列表
			this.getExtendList({data:{
					uid:this.uid,
					level:this.extendType,
					page:this.page,
					pagesize:this.page_size,
				},success:(res)=>{
				if(res.code===200){
					console.log('get extent list success',res)
					this.extendList = res.data.data
					this.listTotal = res.data.total
				} else {
					console.log(res.msg)
				}
			}})
		},
		currentChange(val){
			this.page = val
			this.getExtend()
			console.log(val)
		}
	}
}
</script>

<style scoped>
.wrap .wrap_body{display: flex;flex-direction: column;}
.center{display: flex;flex: 1;}
.ucenter_main{padding: 30px 0 40px;display: flex;justify-content: space-between;flex: 1;}

.right_con{width: 980px;background: #fff;}
.extend_main{padding: 36px 40px 40px;}
.links_area{width: 100%;height: 80px;background-color: #fff9fa;border-radius: 4px;border: solid 1px #f49492;margin-top: 30px;font-size: 14px;}
.links_area>p{display: flex;align-items: center;margin: 16px 20px 0;line-height: 1;}
.links_area>p>a{color: #1a73e8;}
.links_area>p>a:hover{text-decoration: underline;}
.links_area>p .icon-fuzhi{font-size: 14px;color: #666666;margin-left: 10px;cursor: pointer;}

.tabs_wrap{width: 100%;height: 60px;border-bottom: 1px solid #eee;display: flex;margin-top: 12px;}
.tabs_wrap .tab{height: 100%;padding: 30px 16px 0;position: relative;line-height: 1;cursor: pointer;font-size: 14px;color: #666;box-sizing: border-box;}
.tabs_wrap .tab.active{font-weight: bold;color: var(--red);}
.tabs_wrap .tab.active::after{content: '';width: 100%;height: 3px;background: var(--red);position: absolute;left: 0;bottom: 0;z-index: 2;}

.business_list{padding-top: 10px;display: flex;flex-wrap: wrap;}
.business_list>li{width: 290px;height: 150px;background: #fff;border: 1px solid #ddd;border-radius: 6px;margin: 14px 14px 0 0;font-size: 12px;line-height: 1;box-sizing: border-box;}
.business_list>li:nth-child(3n){margin-right: 0;}
.business_list>li .infos{margin: 16px 16px 0;display: flex;align-items: center;}
.business_list>li .infos .con_box{flex: 1;width: 0;margin-right: 20px;}
.business_list>li .infos .con_box .phone{font-size: 16px;}
.business_list>li .infos .con_box .time{color: #999;margin-top: 10px;}
.business_list>li .infos .logo{width: 48px;height: 48px;border-radius: 50%;overflow: hidden;}
.business_list>li .contents{margin: 30px 8px 0;display: flex;}
.business_list>li .contents .con{flex: 1;text-align: center;}
.business_list>li .contents .con .t2{color: #999;margin-top: 8px;}
.turn_page{display: flex;justify-content: flex-end;margin-top: 30px;}
.nodata{margin: 50px auto 0;text-align: center;color: #999;font-size: 16px;}









</style>
<style>
/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus ,.el-input__inner:focus ,.el-range-editor.is-active, .el-range-editor.is-active:hover{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover ,.el-date-table td.today span ,.el-date-table td.available:hover ,.el-time-panel__btn.confirm{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner ,.el-date-table td.end-date span, .el-date-table td.start-date span{background-color: var(--red);}
</style>
